import RootLink from "next/link"
import {type ButtonHTMLAttributes, type ReactNode, forwardRef, type AnchorHTMLAttributes} from "react"
import {twMerge} from "tailwind-merge"
import {type VariantProps, cva} from "cva"

type SharedProps = {
  after?: ReactNode
  before?: ReactNode
} & Required<VariantProps<typeof button>>

const button = cva({
  base: "flex items-center justify-center gap-2 py-2.5 px-4 shadow-sm rounded-lg hover:opacity-70 outline-none focus-visible:ring disabled:opacity-30 text-sm font-semibold",
  variants: {
    intent: {
      primary: "bg-brand ring-brand-200/25 text-white",
      secondary: "border-gray-300 border bg-white text-gray-900",
    },
  },
})

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & SharedProps

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({intent, className, after, before, children, ...props}, ref) => {
    return (
      <button ref={ref} {...props} className={twMerge(button({intent}), className)}>
        {before}
        {children}
        {after}
      </button>
    )
  },
)

Button.displayName = "Button"

type Link = Parameters<typeof RootLink>[0] & SharedProps

export const Link = forwardRef<HTMLAnchorElement, Link>(
  ({intent, className, children, after, before, ...props}, ref) => {
    return (
      <RootLink ref={ref} {...props} className={twMerge(button({intent}), className)}>
        {before}
        {children}
        {after}
      </RootLink>
    )
  },
)

Link.displayName = "Link"

type Anchor = AnchorHTMLAttributes<HTMLAnchorElement> & SharedProps

export const Anchor = forwardRef<HTMLAnchorElement, Anchor>(
  ({intent, className, children, after, before, ...props}, ref) => {
    return (
      <a ref={ref} {...props} className={twMerge(button({intent}), className)}>
        {before}
        {children}
        {after}
      </a>
    )
  },
)

Anchor.displayName = "Anchor"
